import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { navigate } from "gatsby";
import { IsMobileDevice } from "../utils/functions";
import Menu from "../components/menu.jsx";
import FAQContent from "../components/faq-content";
import Seo from "../components/seo";
import { isMobileAtom } from "../recoil/atoms.js";
import Cookies from "js-cookie";
import { window, document } from "browser-monads";

const FAQPage = () => {
    const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);

    useEffect(() => {
        setIsMobile(IsMobileDevice());
    }, []);

    useEffect(() => {
        // const td = Cookies.get("_td");
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "FAQ",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: `${window.location.href}`,
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight / 100}px`
        );
    }, []);

    useEffect(() => {
        let local = localStorage.getItem("age");
        let session = sessionStorage.getItem("age");
        if (!local && !session)
            navigate("/agegate", {
                state: {
                    newPath: "/thank-you",
                },
            });
    }, []);

    return (
        <>
            <Seo title="Stella Artois" />
            <Menu isMobile={isMobile} onlySocialMediaIcons={true} />
            <FAQContent isMobile={isMobile} />
        </>
    );
};

export default FAQPage;
