import React, { useState, useEffect } from "react";
import logo from "../svg/bocado-club-black-letters.svg";
import star from "../svg/bocado-star.svg";
import search from "../svg/search.svg";
import minus from "../svg/collapse-minus.svg";
import plus from "../svg/collapse-plus.svg";
import styles from "../styles/faq-content.module.scss";

const questionsAndAnswers = [
    {
        question: "¿Qué es Bocado Club?",
        answer:
            "- Bocado Club une dos de las cosas que más nos gustan: la comida y la cerveza. Siendo parte accedés a beneficios exclusivos.",
    },
    {
        question: "¿Cómo me registro?",
        answer: "- ¡Bonjour! Te registrás en nuestra web. Es súper fácil 😉",
    },
    {
        question: "¿Cómo me llegan los beneficios?",
        answer:
            "- A través de nuestro newsletter, que llega al mail con el que te registraste.",
    },
    {
        question: "¿A qué beneficios puedo acceder?",
        answer:
            "- Podés acceder a descuentos, sorteos exclusivos, preventas, invitaciones a eventos, contenido original y más.",
    },
    {
        question: "¿Tengo que pagar para hacerme miembro?",
        answer: "- No, la suscripción a Bocado Club es gratuita.",
    },
];

const FAQContent = ({ isMobile }) => {
    const [searchInput, setSearchInput] = useState("");
    const [showQuestions, setShowQuestions] = useState(null);
    const [
        filteredQuestionsAndAnswers,
        setFilteredQuestionsAndAnswers,
    ] = useState([]);

    const updateShowQuestions = (index) => {
        const showQuestionsCopy = [...showQuestions];
        showQuestionsCopy[index] = !showQuestions[index];
        setShowQuestions(showQuestionsCopy);
    };

    useEffect(() => {
        if (searchInput) {
            let results = [];
            questionsAndAnswers.forEach((item) => {
                if (
                    item.question
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                    item.answer
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                )
                    results.push(item);
            });
            setFilteredQuestionsAndAnswers(results);
        } else setFilteredQuestionsAndAnswers(questionsAndAnswers);
    }, [searchInput]);

    useEffect(() => {
        setShowQuestions(filteredQuestionsAndAnswers.map(() => false));
    }, [filteredQuestionsAndAnswers]);

    return (
        <div className={styles.faqContentContainer}>
            <img
                src={star}
                alt="Stella Artois"
                className={styles.faqContentStar}
            />
            <div className={styles.faqContentTitle}>
                Bienvenidos a FAQs de bocado club
            </div>
            <div className={styles.faqContentSubtitle}>
                ¿En qué podemos ayudarte?
            </div>
            <div className={styles.faqContentInstructions}>
                Una la función de búsqueda o elige alguna categoría de abajo.
            </div>
            <div className={styles.faqContentSearchContainer}>
                <input
                    className={styles.faqContentSearchInput}
                    placeholder="Buscar"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <img
                    src={search}
                    alt="Buscar"
                    className={styles.faqContentSearchIcon}
                />
            </div>
            <div className={styles.faqContentQuestionsAndAnswersContainer}>
                {filteredQuestionsAndAnswers.map((item, index) => (
                    <div
                        className={styles.faqContentQuestionAndAnswerContainer}
                    >
                        <div
                            className={
                                styles.faqContentQuestionAndButtonContainer
                            }
                        >
                            <div className={styles.faqContentQuestion}>
                                {item.question}
                            </div>
                            <img
                                onClick={() => {
                                    updateShowQuestions(index);
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "FAQ",
                                        event_action: `${
                                            showQuestions[index]
                                                ? "Collape"
                                                : "Expand"
                                        }`,
                                        event_label: `${item.question}`,
                                        component_name: "boton_ver_mas",
                                    });
                                }}
                                src={showQuestions[index] ? minus : plus}
                                className={styles.faqContentExpandButton}
                            />
                        </div>
                        {showQuestions[index] && (
                            <div className={styles.faqContentAnswer}>
                                {item.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <img
                src={logo}
                alt="Stella Artois"
                className={styles.faqContentBottomLogo}
            />
        </div>
    );
};

export default FAQContent;
